<!--登录页面弹窗提示-->
<template>
    <el-dialog
        v-model="dialogVisible"
        title="Tips"
        width="400px"
    >
        <span>{{langArr['html.span.tips']}}</span>
        <br>
        <el-checkbox v-model="checked1" :label="langArr['html.el-checkbox.label']" size="large" style="float:right"/>
        <template #footer>
            <el-button type="primary" @click="clearView">{{langArr['html.el-button.clearView']}}</el-button>
            <el-button type="danger" @click="toDonwload">{{langArr['html.el-button.toDonwload']}}</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import {ref,defineExpose} from "vue";
import cookies from "../../utils/cookies";
import jsCookie from 'js-cookie';
import lang from '../../lang/index';
const langArr = ref(lang.getLang('login','alertTips'));

let cookieField = 'alertTips';
let dialogVisible = ref(false);
const openView = () => {
    let cookieValue = cookies.getCookie(cookieField);
    if (cookieValue){
        return;
    }
    dialogVisible.value = true;
}
defineExpose({
    openView
});

let checked1 = ref(false);
const clearView = () => {
    dialogVisible.value = false;
    if (checked1.value == true){
        // cookies.setCookie(cookieField,1);
        jsCookie.set(cookieField,1,{ expires: 30 });
    }
}
const toDonwload = () => {
    window.open('https://www.google.cn/intl/zh-CN/chrome/');
    clearView();
}
</script>