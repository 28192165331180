<template>
    <div class="main" v-loading=loading v-show="isShow">
        <div class="login-container">
            <el-card>
                <span style="text-align: center;display:block;color: #909399">{{this.lang['title']}}</span>

                <el-input v-model="form.username"
                          :placeholder="this.lang['form.user']"
                />
                <el-input
                    v-model="form.password"
                    type="password"
                    :placeholder="this.lang['form.pass']"
                    show-password
                />

                <el-select
                    v-model="form.selectLang"
                    :placeholder="this.$lang.getLanguage() == 'zh' ? '中文' : 'EngLish'"
                    @change="changLang">

                    <el-option label="中文" value="zh" :key="1"></el-option>
                    <el-option label="English" value="en" :key="2"></el-option>
                    <el-option label="..." value="wait" :key="3"></el-option>
                </el-select>

                <el-tag class="ml-2" type="info" style="margin-left: 100px">Version:{{version_Str}}</el-tag>
                <el-tag class="ml-2" type="info" style="margin-left: 10px">Language:{{selectLang}}</el-tag>

                <el-button class="buttonClass" type="primary" @click="login" :disabled="isDisabled">{{lang['form.login']}}</el-button>
                <br>
                <el-button class="buttonClass" type="warning" @click="resetForm" :disabled="isDisabled">{{lang['form.reset']}}</el-button>
            </el-card>
        </div>
        <AlertTips ref="AlertTips"/>

    </div>
</template>

<script>
import { ElMessageBox ,ElMessage } from 'element-plus'
import AlertTips from "./alertTips";
import jsCookie from 'js-cookie';

export default {
    name: "index",
    components: {AlertTips},
    data() {
        return {
            form: {
                username: '',
                password: ''
            },
            lang:this.$lang.getLang('login','index'),
            selectLang:this.$lang.getLanguage(),
            //等待加载
            loading:true,
            isShow:true,
            version:0,//当前操作版本
            version_Str:'null',
            isDisabled:false,
        }
    },
    methods:{
        resetForm(){
            this.form = {
                username: '',
                password: ''
            };
        },
        login(){
            let form = this.form;
            if (form.username == '' || form.password == '' ){
                ElMessageBox.alert(this.lang['form.user-pass.tips'], 'Error')
                return;
            }
            this.isDisabled = true;
            let username = form.username,
                password = form.password,
                version = this.version;
            ElMessage({showClose: true, message: this.lang['tips.wait']});
            this.$api.post('Api.Auth/login',{username,password,version}).then((res)=>{
                this.isDisabled = false;
                let code = res.data.code == null ? -1: res.data.code;
                if (code == 100){
                    let token = res.data.auth == null ? null : res.data.auth;
                    if (token != null){
                        this.$cookie.setToken(token);
                        ElMessage({showClose: true, message: this.lang['tips.succ'],type:'success'});
                        this.loading = true;
                        this.jumpMember();
                        return;
                    }
                }
                //执行到这登录失败
                this.$alert(this.lang['tips.fail'],'Error Code:' + code)
            }).catch((err)=>{
                this.isDisabled = false;
                this.$helper.axiosCatch(err);
            })
        },
        //更改语言
        changLang(val){
            if (this.selectLang != val){
                this.$lang.setLanguage(val);
                location.reload();
            }
        },
        //登录成功,跳转到后台页面
        jumpMember(){
            setTimeout(()=>{
                //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
                this.$router.push({
                    name:"member.index"
                });
                //延迟时间：3秒
            },1500);
        },
        //检查[version]字段是否正确,正确则全局设置[version]字段(cookie)
        checkVersionAndSet(){
            let bool = false , version = 0;
            let str = this.$route.params['version'] ?? 'default';
            if (str == 'c'){
                bool = true;
                version = 1;
            }else if (str == 'y'){
                bool = true;
                version = 2;
            }
            if (bool){
                this.version = version;
                this.$cookie.setCookie('version',version);
                this.version_Str = str;
            }else {
                this.isShow = false;
                this.$alert(this.lang['tips.version'])
            }
        },
        //第一次使用判断当前语言,并自动切换中英文
        checkNowLang(){
            //基于Cookie检查是否已做过检查
            let cookieField = 'checkLang';
            let cookieValue = this.$cookie.getCookie(cookieField);
            if (cookieValue){
                return;
            }

            let langs = navigator.languages;
            if (!langs){
                return;
            }
            let isCn = false;
            for (let langsKey in langs) {
                let name = langs[langsKey];
                let bool =
                    this.$helper.checkExistString(name,'zh')
                    ||
                    this.$helper.checkExistString(name,'cn');
                if (bool){
                    isCn = true;
                    break;
                }
            }
            //切换语言
            jsCookie.set(cookieField,1,{ expires: 30 });
            this.changLang(isCn ? 'zh' : 'en');
        },
    },
    mounted() {
        this.checkNowLang();
        //检查是否附带[version]字段信息
        this.checkVersionAndSet();
        //检查是否登录
        let token = this.$cookie.getToken();
        // console.log('mounted.token',token);
        if (token != null && token.length>30){
            //跳转到后台页面
            this.jumpMember();
            return;
        }
        this.loading = false;
        this.$refs.AlertTips.openView();
    }
}
</script>
<style scoped>
.main{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 150px;
}

.login-container {

}

/deep/.el-input__inner{
    margin-top: 15px;
}

.buttonClass{
    width: 100%;
    margin-top: 10px;
}
</style>